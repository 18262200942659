import Router from 'next/router'
import React from 'react'

import ErrorLayout from '@components/Error'

function Error({ statusCode }) {
  return <ErrorLayout statusCode={statusCode} />
}

Error.getInitialProps = ({ res, err, asPath }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404

  // avoid 404 for local development on page reload
  if (__DEV__ && statusCode && statusCode === 404) {
    const basePath = asPath.split('?')
    if (asPath.match(/\/$/) || basePath[0].match(/\/$/)) {
      let withoutTrailingSlash = asPath.substr(0, asPath.length - 1)
      if (basePath[1]) {
        withoutTrailingSlash = `${basePath[0].substr(0, basePath[0].length - 1)}?${basePath[1]}`
      }
      if (res) {
        res.writeHead(302, {
          Location: withoutTrailingSlash,
        })
        res.end()
      } else {
        Router.push(withoutTrailingSlash)
      }
    }
  }

  return { statusCode }
}

export default Error
